<template>
  <v-container>
    <div class="box-shadow mb-0">
      <div class="faq">
        <h3 class="section__title">Stromzähler – FAQs</h3>
        <ul class="faq-block-wrap">
          <li class="faq-item" v-for="(item, index) of faqItems" :key="index">
            <div class="faq-item-block">
              <div class="faq-title" @click="toggleItem(index)">
                <span class="faq-marker epicon" :class="{'epicon-minus': isActive === index, 'epicon-plus': isActive !== index}"></span>
                {{ item.title }}
              </div>
              <transition name="slide">
                <div v-if="isActive === index">
                  <div class="faq-answer" v-html="item.content" />
                </div>
              </transition>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </v-container>
</template>

<script>
  export default {
    name: 'FAQ',
    data() {
      return {
        isActive: 0,
        faqItems: [
          {
            title: 'Was ist ein SmartMeter und worin besteht der Unterschied zu einem analogen Stromzähler?',
            content: 'Ein digitaler Stromzähler misst den Stromverbrauch des Nutzers und speichert, anders als ein ' +
                'analoger Ferraris-Zähler, die Verbrauchswerte in Tages-, Wochen- und Monatswerten der letzten zwei Jahre. ' +
                'Je nach Zählertyp können die Verbrauchswerte auch fernausgelesen und in Echtzeit visualisiert werden.'
          },
          {
            title: 'Wozu benötige ich einen SmartMeter?',
            content: 'Unser SmartMeter bietet Ihnen dank innovativer Technologie mehr Komfort. Die Fernauslesung ' +
                'übermittelt uns automatisch Ihren aktuellen Zählerstand und erspart Ihnen den monatlichen Gang in den ' +
                'Keller. Somit können wir die Reichweite Ihres Strompakets noch präziser bestimmen und Sie bei ' +
                'überdurchschnittlich hohem Verbrauch warnen.'
          },
          {
            title: 'Muss ich für den SmartMeter monatlich zahlen?',
            content: 'Nein, Sie gehören zu einer ausgewählten, exklusiven Testgruppe, der wir den SmartMeter kostenlos zur ' +
                'Verfügung stellen. Dieses Angebot ist nur in Verbindung mit dem Abschluss eines Strompakets gültig.'
          },
          {
            title: 'Wem gehört der SmartMeter?',
            content: 'Der Stromzähler gehört dem Messstellenbetreiber. Dieser ist für Ein- und Ausbau, Betrieb, Wartung und das Monitoring des Zählers zuständig.'
          },
          {
            title: 'Kann ich den SmartMeter auch als Mieter einbauen lassen?',
            content: ' Ja, als Mieter haben Sie, genau wie bei der Wahl ihres Stromvertrags, das Recht einen Messstellenbetreiber frei zu wählen und somit auch einen Zähler einbauen zu lassen.'
          },
          {
            title: 'Wie läuft der Zählerwechsel ab?',
            content: 'Der Wechsel Ihres Stromzählers ist ganz einfach: Zunächst vereinbaren wir mit Ihnen einen für Sie möglichst komfortablen Installationstermin, ' +
                'zu dem unser Techniker bei Ihnen vor Ort den Zählerwechsel vornimmt. Am Tag der Installation müssen Sie dem Techniker Zugang zu Ihrem Zählerschrank ' +
                'gewähren. Nach erfolgreichem Umbau nimmt unser Techniker Ihren alten Zähler mit und übermittelt den letzten Zählerstand an uns. Anschließend wird der ' +
                'Zähler entweder an Ihren alten Messstellenbetreiber zurückgegeben oder entsorgt.'
          },
          {
            title: 'Welche technischen Voraussetzungen benötige ich für den SmartMeter?',
            content: 'Für den Wechsel des Stromzählers muss der Zugang zu dem Zählerschrank ' +
                'für unseren Techniker gewährleistet werden. Zudem behalten wir uns vor, nur an ' +
                'Zählerschränken zu montieren, die dem aktuellen Stand der Technik entsprechen. Sollten Sie dazu Fragen' +
                'haben, schicken Sie uns einfache eine Mail mit Fotos von Ihrem Zählerschrank an <a href="mailto:https:strompakete@eprimo.de" >strompakete@eprimo.de</a> .'
          },
          {
            title: 'Wie funktioniert die Datenübertragung und wie werden die Daten geschützt?',
            content: 'Die Zählerdaten werden über das Mobilfunknetz uns übermittelt. Die Kommunikation über LTE ermöglicht ' +
                'eine schnelle und sichere Datenübertragung. Der Schutz der Daten ist dabei durch das MsbG und die Richtlinien des ' +
                'Bundesamts für Sicherheit in der Informationstechnik gewährleistet.'
          }
        ]
      }
    },
    methods: {
      toggleItem: function (index) {
        this.isActive = this.isActive === index ? null : index;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .slide-enter-active {
    transition-duration: 0.35s;
    transition-timing-function: ease-in;
  }

  .slide-leave-active {
    transition-duration: 0.35s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  }

  .slide-enter-to, .slide-leave {
    max-height: 100px;
    overflow: hidden;
  }

  .slide-enter, .slide-leave-to {
    overflow: hidden;
    max-height: 0;
  }

  .faq {
    &-block-wrap {
      list-style: none;
      margin-top: 35px;
    }

    &-item:not(:last-of-type) {
      padding-bottom: 20px;
      border-bottom: 1px solid $grey-light;
      margin-bottom: 20px;
    }

    &-item:last-of-type {
      padding-bottom: 5px;
    }

    &-title {
      cursor: pointer;
      position: relative;
    }

    &-item-block {
      padding-left: 40px;
    }

    &-answer {
      padding: 22px 40px 0 0;
    }

    &-marker {
      position: absolute;
      left: -40px;
      top: -2px;
      color: $white;
      width: 24px;
      height: 24px;
      display: block;
      border-radius: 50%;
      background: $green;
      line-height: 1.2;

      &:before {
        margin-left: -1px;
      }
    }
  }
</style>
