<template>
  <div>
  <GreenBlock>
    <v-container>
      <h1 class="page-title max-width mb-9">Vielen Dank für Ihre <br>
        SmartMeter-Bestellung!</h1>
      <div class="green-section__content mb-5">
        <div class="green-section--left">
          <div class="page-subtitle">
            Sie erhalten in Kürze per E-Mail weitere Informationen <br>
            von uns zu den nächsten Schritten.
          </div>
          <div class="text-center-mobile">
            <a class="btn" href="https://www.eprimo.de">eprimo.de besuchen</a>
          </div>
        </div>
        <div class="eprimo-smile-img">
          <img src="~@/assets/images/eprimo-face-3.svg" alt="eprimo-face">
        </div>
      </div>
      <div class="text-center-mobile mob-show mb-5">
        <a class="btn" href="https://www.eprimo.de">eprimo.de besuchen</a>
      </div>
    </v-container>
  </GreenBlock>
    <FAQ/>
  </div>
</template>

<script>
import GreenBlock from "@/components/GreenBlock";
import FAQ from '@/components/FAQSmartOrder';

export default {
  name: 'ThankYouOrder',
  components: {
    GreenBlock,
    FAQ
  }
}
</script>

<style lang="scss" scoped>

.green-section.green-section--big .green-section--right {
  margin: -30px 50px -70px 0;
}

.eprimo-smile-img {
  float: right;
  margin: -83px 0 0 130px;

  img {
    width: 230px;
  }
}

.page-subtitle {
  margin-bottom: 30px;
}

.green-section__content .page-subtitle {
  font-size: 18px;
  font-weight: 600;
}

.green-section {
  padding: 50px 0 20px;
  margin-bottom: 30px;
}

.mob-show {
  display: none;
}

@media (max-width: 767px) {

  .green-section {
    padding: 20px 0 20px;
    margin-bottom: 30px;
  }

  .page-title {
    font-size: 20px;
    margin-bottom: 30px !important;
  }

  .page-subtitle {
    padding-right: 25px;
  }

  .eprimo-smile-img img {
    width: 175px;
  }
  .btn:not(.popover-email) {
    width: 100%;
  }

  .eprimo-smile-img {
    margin: -60px -22px 0 -45px;
  }

  .green-section__content .page-subtitle {
    font-size: 14px;
  }

  .text-center-mobile {
    display: none;
  }

  .text-center-mobile.mob-show {
    display: block;
  }

}

</style>
